import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home/Home.vue'

import NotFound from '../views/NotFound/NotFound.vue'

import Payment from '../views/Payment/Payment.vue'

const routes = [
  // { path: '/', name: 'redirect', redirect: '/en', component: Home },
  
  { path: '/en/payment/:PaymentReference', name: 'Payment', component: Payment },
  
  { path: '/:catchAll(.*)', component: NotFound },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
