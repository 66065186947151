<template>
<div class="main">
    <router-view/>

    <!-- <Footer /> -->
    <!-- <CookieConsent /> -->
</div>

</template>

<script>
    // import Footer from '@/components/Footer.vue';
    // import CookieConsent from '@/components/CookieConsent.vue';
    // import getMyFavouriteLanguage from "@/services/language.js";
    
    // import apiPlatform from '@/config/apiPlatform.js'
    // import apiInternational from '@/config/apiInternational.js'
    export default {
        components: {
            // Footer,
            // CookieConsent
        },
        data: function () {
            return {
                language: 'en',
                group: 'dne-platform',
                dataLanguage: [],
                // dataPhaseCurrent: [],
                flagSelected: 'en',
                flagImg: '51021d86-49e9-4beb-804b-dfadf7a51300',
                statusLogin: {
                    logged: false
                },
                loginErrors: ""
            }
        },

        beforeMount(){
            // this.loginStatus();
            // this.flagUrl()
        },

        async mounted(){
            // const resumeLanguage = await apiPlatform.get('api/v1/platform/language')
            // this.dataLanguage = resumeLanguage.data
            // this.$store.commit('allLanguages',this.dataLanguage)

            // this.flagImg = this.dataLanguage.filter(lang => lang.code === this.flagSelected)[0].flag
            // this.$store.commit('preLanguageFlag',this.flagImg)

            // const resumePhaseCurrent = await apiInternatioanl.get('/api/v2/ico/phase/current')
            // this.dataPhaseCurrent = resumePhaseCurrent.data
            // this.$store.commit('phaseCurrent',this.dataPhaseCurrent)
        },

        methods: {
            // loginStatus(){
            //     const tokenValue = localStorage.getItem('accessToken')

            //     if ( tokenValue != undefined || tokenValue != null ){
            //         api.get('/api/v1/platform/account/profile/')
            //         .then(response => {
            //             this.$store.commit('profile',response.data)
            //             this.$store.commit('loginStatus', true)
            //         })
            //         .catch(error => {
            //             console.log(JSON.stringify(error))
            //             let errorsLogin = JSON.stringify(error.response.status)
            //             this.loginErrors = errorsLogin

            //             if ( errorsLogin == 401 || errorsLogin == 403 ){
            //                 localStorage.removeItem('accessToken');
            //             }
            //         });
            //     }

            //     this.statusLogin.logged = false
            //     this.$store.commit('loginStatus', false)
            // },

            // flagUrl(){
            //     let code = String(window.location.pathname).split("/")[1];
            //     let flag = '51021d86-49e9-4beb-804b-dfadf7a51300'
            //     let language = "English"

            //     if ( !code ) {
            //         code = 'en',
            //         this.$store.commit('preLanguageFlag',flag)
            //         localStorage.setItem('codeLanguage',code);
            //         localStorage.setItem('flagLanguage','51021d86-49e9-4beb-804b-dfadf7a51300');
            //     } else{
            //         this.flagSelected = code
            //         this.$store.commit('preLanguage',code)
            //         this.$store.commit('preLanguageName',language)
            //     }

            //     if (this.flagSelected === "ar"){
            //         document.body.classList.add('ar');
            //     }
            // },

            // changeLanguage(parLanguage){
            //     this.language = parLanguage;
            //     let url = String(window.location.pathname).split("/");
                
            //     url[1] = parLanguage;
            //     window.location.href = `${url.join('/')}`;
            // },
            
            // getMyLanguage(group, code){
            //     return getMyFavouriteLanguage(group, code)
            // }
        }
    }
</script>

<style src="@/assets/scss/_custom-app.scss" lang="scss" />