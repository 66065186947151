<template>
   <section class="payment">

      <!-- TOP -->
      <div class="top">
            <img src="~@/assets/images/header/dne-pay.svg" alt="DNE Pay" />
      </div>

      <div class="load" v-if="!showContent && !errors.invoice"></div>

      <div class="box box-error" v-if="errors.invoice">
         Houve um erro ao carregar a fatura
      </div>

      <!-- PAYER -->
      <div class="box box-payer" v-if="showContent">
         <p>Fatura de {{dataPayment.seller.full_name}}</p>

         <div class="resume" :class="{success: dataPayment.status == 'PaymentConfirmed'}">
            <!-- <div class="value">{{dataPayment.order.total_price.toLocaleString('pt-BR', { style: 'currency', currency: dataPayment.order.order_item.asset_short })}}</div> -->
            <div class="value">R$ {{parseFloat(dataPayment.order.total_price).toFixed(2) / 100}}</div>
            <div class="euro">{{dataPayment.order.order_item.price.toLocaleString('pt-BR', { style: 'currency', currency: "EUR" })}}</div>
            <p>Pagar até {{dateTime(dataPayment.pay_up_to)}}</p>
         </div>

         <div class="to-from">
            <div><span>De:</span><span>{{dataPayment.buyer.full_name}}</span></div>
            <div><span>Para:</span><span>{{dataPayment.seller.full_name}}</span></div>
         </div>

         <div class="btn-primary btn-qrcode" v-if="!showQrCode.show && dataPayment.status == 'WaitingPayment'" @click="generateQrCode()">Pagar com PIX</div>

         <div class="msg msg-created" v-if="dataPayment.status == 'Created'">Fatura ainda não disponível</div>
         <div class="msg msg-cancelled" v-if="dataPayment.status == 'PaymentCancelled'">Esta fatura foi cancelada</div>
         <div class="msg msg-confirmed" v-if="dataPayment.status == 'PaymentConfirmed'">O pagamento foi confirmado</div>


         <!-- SHOW QRCODE -->
         <div class="error-qrcode" v-if="errors.qrcode">
            <p>Não foi possível gerar o QR Code</p>
            <p>Tente novamente mais tarde.</p>
         </div>

         <div class="load" v-if="showQrCode.load"></div>

         <div class="show-qrcode" v-if="showQrCode.show">
            <div class="anc" id="scanQRCode"></div>

            <div class="scan-cont" v-if="!showQrCode.load">
                  <div class="header-scan">
                     Faça a leitura de um QR Code de pagamento.
                  </div>

                  <div class="qrcode">
                     <qrcode-vue :value="qrCode.value" :size="qrCode.size" level="L" />
                  </div>

                  <div class="copy">
                     <small>{{qrCode.value}}</small>
                     <span @click="copyAddress(qrCode.value)"></span>
                  </div>
            </div>
         </div>
      </div>


      <!-- RECEIVER -->
      <div class="box box-receiver" v-if="showContent">
         <p class="invoice"><span>Fatura</span> <strong>#{{dataPayment.reference}}</strong></p>
         <p class="receiver">{{dataPayment.order.order_item.product_variant_name}}</p>

         <div class="resume">
            <!-- <div><span>Valor da fatura:</span><span>{{dataPayment.order.total_price.toLocaleString('pt-BR', { style: 'currency', currency: dataPayment.order.order_item.asset_short })}}</span></div> -->
            <div><span>Fatura em Real:</span><span>R$ {{parseFloat(dataPayment.order.total_price).toFixed(2) / 100}}</span></div>
            <div><span>Fatura em Euro:</span><span>{{dataPayment.order.order_item.price.toLocaleString('pt-BR', { style: 'currency', currency: "EUR" })}}</span></div>
            <div><span>Pagar até:</span><span>{{dateTime(dataPayment.pay_up_to)}}</span></div>
            <div>
               <span>Status:</span>
               <span class="created" v-if="dataPayment.status == 'Created'">Fatura indisponível</span>
               <span class="cancelled" v-if="dataPayment.status == 'PaymentCancelled'">Cancelado</span>
               <span class="waiting" v-if="dataPayment.status == 'WaitingPayment'">Aguardando pagamento</span>
               <span class="confirmed" v-if="dataPayment.status == 'PaymentConfirmed'">Pagamento confirmado</span>
            </div>
         </div>
      </div>
   </section>

   <!-- MODAL QR CODE -->
   <!-- <div class="modal modal-qrcode" v-if="showQrCode.show">
         <div class="modal-cont">
            <div class="modal-close" @click="showQrCode.show = false"></div>
            
            <div class="load" v-if="showQrCode.load"></div>

            <div class="scan-cont" v-if="!showQrCode.load">
               <div class="header-scan">
                  <div class="instructions">Faça a leitura de um QR Code de pagamento</div>
                  <div class="close-scan" @click="scanQRCode = false"></div>
               </div>

               <div class="qrcode">
                  <qrcode-vue :value="qrCode.value" :size="qrCode.size" level="L" />
               </div>
            </div>
         </div>
      </div> -->
</template>

<script>
   import apiDnePay from '@/config/apiDnePay.js'

   // import getMyFavouriteLanguage from "@/services/language.js";
   import QrcodeVue from "qrcode.vue";
   import moment from "moment";

   export default {
   components: {
      QrcodeVue,
   },

   data() {
      return {
         showContent: false,

         dataPayment: {},

         errors:{
            invoice: false,
            qrcode: false
         },

         showQrCode: {
            show: false,
            load: false,
         },

         qrCode: {
            value: "",
            size: 220,
         },
      };
   },

   async mounted(){
      await apiDnePay.get('/api/v1/pay/payment/' + this.$route.params.PaymentReference)
      .then(response => {
         this.dataPayment = response.data
         console.log(this.dataPayment)
         
         setTimeout(() => {
            this.showContent = true
         }, 500);
      })
      .catch(error => {
         console.log(error)
         this.errors.invoice = true
      })
   },

   methods: {
      // getMyLanguage(group, code){
      //    return getMyFavouriteLanguage(group, code)
      // },

      dateTime(value) {
         return moment(value).format("DD/MM/YYYY");
      },

      generateQrCode() {
         this.showQrCode.load = true;

         apiDnePay.get('/api/v1/pay/payment/pix/' + this.dataPayment.reference)
         .then(response => {
            this.qrCode.value = response.data.code
            this.showQrCode.show = true;
            
            setTimeout(() => {
               this.showQrCode.load = false;
            }, 400);

            setTimeout(() => {
               const anc = document.getElementById("scanQRCode");
               if (anc) {
                  anc.scrollIntoView({ behavior: "smooth" });
               }
            }, 500);
         })
         .catch(error => {
            console.log(error)
            this.errors.qrcode = true
            
            setTimeout(() => {
               this.showQrCode.load = false;
            }, 400);
         })
      },

      async copyAddress(value) {
         try {
         await navigator.clipboard.writeText(value);
            this.$toast.success("Endereço copiado");
         }
         
         catch($e) {
            this.$toast.success("Falha ao copiar");
         }
      },
   },

   created() {
      document.title = "DNE Pay";
      document.head.querySelector("meta[name=robots]").content = "noindex, nofollow";
   },
};
</script>

<style src="./custom-payment.scss" lang="scss" scoped />