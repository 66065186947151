<template>
    <div class="not-found">
        <section class="erro404">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="title-secondary">Página não encontrada</h1>
                    </div>
                    <div class="col-12 img-404">
                        <img src="~@/assets/images/icons/404.svg" alt="404">
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
    // import Header from '@/components/Header.vue';
    // import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        components: {
            // Header
        },
        methods: {
            // getMyLanguage(group, code){
            //     return getMyFavouriteLanguage(group, code)
            // },
        },

        created () {
            document.title = "404 | DNE Pay";
            // document.title = this.getMyLanguage("seo","title.404");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-404.scss" lang="scss" scoped />